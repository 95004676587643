import React from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

function findLatestDate(a) {
  return a.date_updated ? a.date_updated : a.date_created;
}

const Services = (props) => {
  const artikel = props.data.directus.Artikel;
  const relevant_artikel = artikel.sort((a, b) => 
      ((findLatestDate(a) < findLatestDate(b)) ? 1 : -1)
    );
  return (
    <Layout bodyClass="page-hofs">
      <SEO title="Rund um den Hof" />
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Rund um den Hof</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-6">
        <div className="row">
          {relevant_artikel.map(edge => (
            <div key={edge.path} className="col-12 col-md-4 mb-1">
              <div className="card service service-teaser">
                <div className="card-content">
                  <h2>
                    <Link to={edge.path}>{edge.title}</Link>
                  </h2>
                  <p>{edge.kurzbeschreibung}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ServicesQuery {
    directus {
      Artikel(sort: "-date_updated", filter: {featured: {_eq: true}}) {
        status
        sort
        title
        kurzbeschreibung
        featured
        path
        date_updated_func {
          day
          year
          month
        }
        date_created_func {
          day
          year
          month
        }
        date_created
        date_updated
      }
    }
  }
`;

export default Services;
